import { Box } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { facility } from '../../../redux/WarehouseSlice/facilitySlice';
import { Form, FormWrapper } from '../../common';
import FacilityDetails from './FacilityDetails';

const Facility = () => {
  const facilityData = useSelector((state) => state.facility);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { control, trigger, getValues } = useForm({
    defaultValues: facilityData,
    mode: 'all',
    criteriaMode: 'all'
  });

  const forms = [
    {
      label: 'Facility Details',
      form: FacilityDetails
    }
  ];

  return (
    <FormWrapper
      heading="Facility"
      buttonClick={() =>
        trigger().then(
          (res) =>
            res &&
            (dispatch(facility({ ...getValues(), isCompleted: true })), navigate('/warehouse'))
        )
      }>
      {forms?.map(({ label, form: FormRenderer }, index) => {
        return (
          <Box key={index}>
            <Form heading={label}>
              <FormRenderer control={control} />
            </Form>
          </Box>
        );
      })}
    </FormWrapper>
  );
};

export default Facility;
