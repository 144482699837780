import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { INTERNAL_SERVER_ERROR, SUCCESS } from '../../../../constants/apiStatusCodes';
import { ERROR_MESSAGE, ERROR_MSG, SUCCESS_MSG } from '../../../../constants/constants';
import { userSignUp } from '../../../../redux/APICaller/authAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { Checkbox, DynamicButton, Input } from '../../../common';
import { DefaultSkeletton } from '../../../common/WebSkeleton';
import styles from '../styles';

const SignUp = () => {
  const {
    control,
    formState: { errors },
    trigger,
    getValues
  } = useForm({
    defaultValues: {
      organisationName: '',
      phoneNumber: '',
      firstName: '',
      lastName: '',
      username: '',
      isAgreed: ''
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mutate, isPending } = useMutation({
    mutationFn: (data) => userSignUp(data),
    onSuccess: (res) => {
      if (res?.status === SUCCESS) {
        if (res?.data?.msg)
          dispatch(showToastMessage({ messageType: SUCCESS_MSG, message: res?.data?.msg }));
        navigate('/success');
      } else if (res?.status !== INTERNAL_SERVER_ERROR) {
        dispatch(
          showToastMessage({ messageType: ERROR_MSG, message: res?.data?.message || ERROR_MESSAGE })
        );
      }
    },
    onError: () => {
      dispatch(showToastMessage({ messageType: ERROR_MSG, message: ERROR_MESSAGE }));
    }
  });

  const handleClick = (res) => {
    if (res) {
      const formData = getValues();
      const payload = {
        email: formData?.username,
        first_name: formData?.firstName,
        // is_agreed: formData?.isAgreed,
        last_name: formData?.lastName,
        organization_name: formData?.organisationName,
        phone_number: formData?.phoneNumber
      };

      mutate(payload);
    }
  };

  return (
    <Box sx={styles.wrapper}>
      <Box>
        <Box sx={styles.heading}>Setup your account</Box>
        <Box sx={styles.description}>Loren epsom simply dummy text for text use.</Box>
      </Box>
      <Box sx={styles.inputWrapper}>
        <Input
          loading={isPending}
          name="organisationName"
          label="Organisation Name"
          customStyles={styles.input}
          placeholder="Organisation name"
          startIcon={<BusinessOutlinedIcon />}
          control={control}
          errors={errors}
          rules={{ required: 'This is a required field' }}
        />
        <Input
          loading={isPending}
          name="phoneNumber"
          label="Phone Number"
          placeholder="Phone Number"
          customStyles={styles.input}
          startIcon={<LocalPhoneOutlinedIcon />}
          control={control}
          errors={errors}
          onKeyPress={(event) => {
            if (!/[0-9+]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          rules={{
            required: 'This is a required field',
            pattern: {
              value: /^(?:\+\d{1,3})?\d{10}$/,
              message: 'Please provide a valid phone number'
            }
          }}
        />
        <Box sx={styles.splitWrapper}>
          <Input
            loading={isPending}
            name="firstName"
            label="First Name"
            placeholder="First Name"
            customStyles={styles.splitInput}
            startIcon={<PersonOutlineOutlinedIcon />}
            control={control}
            errors={errors}
            rules={{ required: 'This is a required field' }}
          />
          <Input
            loading={isPending}
            name="lastName"
            label="Last Name"
            placeholder="Last Name"
            customStyles={styles.splitInput}
            startIcon={<PersonOutlineOutlinedIcon />}
            control={control}
            errors={errors}
          />
        </Box>
        <Input
          loading={isPending}
          name="username"
          label="Email"
          placeholder="Email Address"
          customStyles={styles.input}
          startIcon={<MailOutlinedIcon />}
          control={control}
          errors={errors}
          rules={{
            required: 'This is a required field',
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: 'Please provide a valid email'
            }
          }}
        />
      </Box>
      <Box sx={styles.authWrapper}>
        <DefaultSkeletton loading={isPending}>
          <Checkbox
            name="isAgreed"
            rules={{ required: 'This is a required field' }}
            errors={errors}
            label={
              <Box>
                By clicking Sign up for Free, you agree to Losung360{' '}
                <Box component="span" sx={styles.linkers}>
                  Terms Of Service
                </Box>{' '}
                and{' '}
                <Box component="span" sx={styles.linkers}>
                  Privacy Policy
                </Box>
                .
              </Box>
            }
            control={control}
          />
        </DefaultSkeletton>
      </Box>
      <DynamicButton
        disabled={isPending}
        label="Sign up"
        customStyles={styles.button}
        onClick={() => trigger().then((res) => handleClick(res))}
      />
      <Box sx={styles.footer}>
        <DefaultSkeletton loading={isPending} customStyles={styles.footerLoading}>
          <Box>
            Already have an account?{' '}
            <Box component="span" sx={styles.linkers} onClick={() => navigate('/sign-in')}>
              Log in
            </Box>
          </Box>
        </DefaultSkeletton>
      </Box>
    </Box>
  );
};

export default SignUp;
