import { Box } from '@mui/material';
import React from 'react';
import styles from './styles';

const SuccessPage = ({
  // heading = "You don't have access to this. Your sign-in was successful but you don't have permission to access this resource."
  description = "You don't have access to this. Your sign-in was successful but you don't have permission to access this resource."
}) => {
  return (
    <Box sx={styles.wrapper}>
      {/* <Box sx={styles.heading}>{heading}</Box> */}
      <Box>
        <Box component="img" src="/success.png" sx={styles.image} />
      </Box>
      <Box sx={styles.description}>{description}</Box>
    </Box>
  );
};

export default SuccessPage;
