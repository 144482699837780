import { Box } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userProfile } from '../../../redux/UserSlice/userProfileSlice';
import { Form, FormWrapper } from '../../common';
import AccountInformation from './AccountInformation';
import BillingAddress from './BillingAddress';
import ContactInformation from './ContactInformation';

const UserProfile = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { control, trigger, getValues } = useForm({
    defaultValues: user,
    mode: 'all',
    criteriaMode: 'all'
  });

  const forms = [
    {
      label: 'Account information',
      form: AccountInformation
    },
    {
      label: 'Contact information',
      form: ContactInformation
    },
    {
      label: 'Billing Address',
      form: BillingAddress
    }
  ];
  return (
    <FormWrapper
      heading="Profile Setup"
      buttonClick={() =>
        trigger().then(
          (res) =>
            res && dispatch(userProfile({ ...getValues(), isCompleted: true }), navigate('/'))
        )
      }>
      {forms?.map(({ label, form: FormRenderer }, index) => {
        return (
          <Box key={index}>
            <Form heading={label}>
              <FormRenderer control={control} />
            </Form>
          </Box>
        );
      })}
    </FormWrapper>
  );
};

export default UserProfile;
