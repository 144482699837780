import { Box, IconButton } from '@mui/material';
import Popover from '@mui/material/Popover';
import * as React from 'react';
import styles from './styles';

const CustomPopover = ({ children, icon, label, customStyles }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Box>
      {icon && <IconButton onClick={handleClick}>{icon}</IconButton>}
      {label && <Box onClick={handleClick}>{label}</Box>}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        sx={{ ...styles.mainPopover, ...customStyles }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        {children}
      </Popover>
    </Box>
  );
};
export default CustomPopover;
