import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Button, IconButton } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import { Pagination, Spinner, TableDataRenderer } from '../../../../common';
import styles from '../styles';

const ManageUserTable = ({
  list = [],
  pageData,
  pageClick,
  page,
  isLoading,
  setUnitSelectedList
}) => {
  const [{ width }] = useWindowDimensions();
  const isWebView = width > 1000;

  const navigate = useNavigate();

  const columnDefs = [
    {
      field: 'ID',
      headerName: 'ID',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => <Box sx={styles.lcanId}>{data?.ID}</Box>
    },
    { field: 'Name', headerName: 'Name', flex: isWebView ? 1 : 0 },
    {
      field: 'Email',
      headerName: 'Email',
      minWidth: 350,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => <Box sx={styles.lcanId}>{data?.Email}</Box>
    },
    { field: 'LastLogin', headerName: 'Last Login', flex: isWebView ? 1 : 0 },
    { field: 'Phone', headerName: 'Phone', flex: isWebView ? 1 : 0 },
    {
      field: 'Status',
      headerName: 'Status',
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => (
        <Button
          sx={{
            backgroundColor: data?.Status === 'Enable' ? '#E4FFD8' : '#FFF3D8',
            color: data?.Status === 'Enable' ? '#32830E' : '#BE870D',
            width: 80,
            '&:hover': {
              backgroundColor: data?.Status === 'Enable' ? '#E4FFD8' : '#FFF3D8',
              color: data?.Status === 'Enable' ? '#32830E' : '#BE870D'
            }
          }}>
          {data?.Status}
        </Button>
      )
    },
    {
      field: 'Edit',
      headerName: 'Edit',
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => (
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <IconButton onClick={() => navigate(`/update-user/${data?.id}`)}>
            <EditOutlinedIcon />
          </IconButton>
        </Box>
      )
    }
  ];

  const selectionChanged = (param) => {
    const selectedRows = param?.api?.getSelectedRows();
    setUnitSelectedList(selectedRows);
  };

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? (
          <Spinner />
        ) : (
          <TableDataRenderer
            list={list}
            columnDefs={columnDefs}
            onSelectionChanged={selectionChanged}
          />
        )}
      </Box>
      <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => pageClick(e, pageNumber)}
        />
      </Box>
    </Box>
  );
};

export default ManageUserTable;
