const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem'
  },

  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  heading: {
    fontSize: '24px',
    fontWeight: 500
  },

  button: {
    height: '45px',
    width: '120px',
    borderRadius: '8px'
  }
};

export default styles;
