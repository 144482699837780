import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('wms_access_token');
  const isLoggedIn = !!token;

  if (isLoggedIn) {
    return children;
  } else {
    return <Navigate to="/sign-in" />;
  }
};

export default PrivateRoute;
