import { Box, Drawer } from '@mui/material';
import React, { Suspense, useEffect, useState } from 'react';
import { Spinner } from '../../components/common';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Navbar from './Navbar';
import StepperList from './StepperList';
import styles from './styles';

const Dashboard = ({ children }) => {
  const [{ width }] = useWindowDimensions();
  const isMobile = width < 900;
  const [showStepper, setShowStepper] = useState(false);

  useEffect(() => {
    setShowStepper(!isMobile);
  }, [isMobile]);

  const drawer = (
    <Drawer
      variant="persistent"
      anchor="left"
      open={showStepper}
      elevation={5}
      PaperProps={{ variant: 'outlined' }}
      sx={styles.drawerPaper}>
      <StepperList setShowStepper={setShowStepper} />
    </Drawer>
  );

  return (
    <Box sx={styles.appFrame}>
      {drawer}
      <Navbar showStepper={showStepper} setShowStepper={setShowStepper} />
      <Suspense fallback={<Spinner />}>
        <Box
          sx={{
            ...styles.drawerContent,
            opacity: showStepper && isMobile ? 0.3 : 1,
            width: {
              xs: '100%',
              md: showStepper ? `calc(100% - 270px)` : '100%'
            },
            marginLeft: {
              xs: '0',
              md: showStepper ? '270px' : 0
            }
          }}>
          <Box sx={styles.content} onClick={() => isMobile && setShowStepper(false)}>
            {children}
          </Box>
        </Box>
      </Suspense>
    </Box>
  );
};

export default Dashboard;
