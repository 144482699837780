import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import React, { memo } from 'react';
import NoData from '../NoData';

const TableDataRenderer = ({
  list,
  columnDefs,
  rowHeight = 65,
  onSelectionChanged,
  rowSelection = 'multiple',
  onGridReady,
  getRowHeight
}) => {
  return list?.length ? (
    <AgGridReact
      rowSelection={rowSelection}
      rowData={list || []}
      enableCellTextSelection
      columnDefs={columnDefs}
      rowMultiSelectWithClick
      onGridReady={onGridReady}
      suppressRowClickSelection
      wrapText
      rowHeight={rowHeight}
      animateRows
      suppressAutoSize
      autoHeight
      onSelectionChanged={onSelectionChanged}
      getRowHeight={getRowHeight}
    />
  ) : (
    <NoData />
  );
};

export default memo(TableDataRenderer);
