import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CustomPopover from '../../common/PopOver';
import styles from './styles';

const Navbar = ({ showStepper, setShowStepper }) => {
  const navigate = useNavigate();

  return (
    <Box sx={styles.navBarWrapper}>
      <Box sx={styles.navBarIcon}>
        <IconButton onClick={() => setShowStepper(!showStepper)}>
          <MenuIcon sx={styles.menuIcon} />
        </IconButton>
        <Box
          onClick={() => navigate('/')}
          component="img"
          src="/losungLogo.png"
          alt="losung logo"
          sx={styles.navBarLogo}
        />
      </Box>
      <Box sx={styles.iconWrapper}>
        <IconButton>
          <SettingsIcon sx={styles.icon} />
        </IconButton>
        <CustomPopover icon={<ExitToAppIcon sx={styles.icon} />}>
          <Box
            onClick={() => {
              window.location.reload();
              localStorage.clear();
            }}
            sx={styles.logOutWrapper}>
            Log Out
          </Box>
        </CustomPopover>
      </Box>
    </Box>
  );
};

export default Navbar;
