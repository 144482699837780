const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: {
      xs: '80%',
      sm: '100%'
    },
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center'
  },

  heading: {
    textAlign: 'center',
    fontSize: {
      xs: '22px',
      sm: '26px',
      md: '30px'
    },
    fontWeight: 600
  },

  image: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    objectFit: 'contain',
    width: '100%'
  },

  description: {
    textAlign: 'center',
    fontSize: {
      xs: '12px',
      sm: '14px',
      md: '16px'
    },
    color: '#707070'
  },

  logoWrapper: {
    position: 'absolute',
    top: 10,
    right: 10
  },

  logo: {
    width: {
      xs: '100px',
      sm: '150px',
      md: '200px'
    }
  }
};

export default styles;
