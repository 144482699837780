import { Box, FormControl, InputBase } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { DefaultSkeletton } from '../../WebSkeleton';
import styles from './styles';

const Otp = ({
  name,
  type = 'text',
  errors,
  fullWidth = true,
  rules,
  customStyles,
  inputProps,
  control,
  setValue,
  getValues,
  otpFields = 6,
  loading = false,
  ...rest
}) => {
  const initialValue = useMemo(() => Array(otpFields).fill(''), [otpFields]);
  const [state, setState] = useState(initialValue);
  const otpIterations = Array(otpFields).fill(0);
  const [focusIndex, setFocusIndex] = useState(0);
  const ref = useRef(null);

  const otpValue = getValues(name);

  useEffect(() => {
    if (!otpValue) setState(initialValue);
  }, [otpValue, initialValue]);

  useEffect(() => {
    ref?.current?.focus();
  }, [focusIndex]);

  const handleOnChange = (index, targetValue) => {
    const asciiValue = targetValue?.charCodeAt(0);
    const isNumeric = asciiValue >= 48 && asciiValue <= 57;
    targetValue = isNumeric ? targetValue : '';
    setState((val) => {
      const newState = [...val];
      newState[index] = targetValue;
      return newState;
    });

    if (isNumeric) {
      let currFocIndex = index + 1 === otpFields ? -1 : index + 1;
      setFocusIndex(currFocIndex);
    }

    let reduxHookState = getValues(name).split('');
    reduxHookState[index] = targetValue === '' ? ' ' : targetValue;
    reduxHookState = reduxHookState.join('');
    setValue(name, reduxHookState);
  };

  const handleKeyDown = (index, key) => {
    if (key === 'Backspace' || key === 'Delete') {
      state[index] = '';
      setFocusIndex(index - 1);
    }
  };

  const handlePaste = (e) => {
    const pastedData = e.clipboardData.getData('text/plain').trim().slice(0, otpFields);
    if (!isNaN(+pastedData)) setState(pastedData.split(''));
  };

  const showError = !!errors[name];

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        ...rules,
        validate: {
          ...rules.validate,
          isEmpty: (value) =>
            !value || value.length === otpFields || `${otpFields - value.length} fields are empty`
        }
      }}
      render={({ field }) => (
        <Box sx={styles.otpWrapper}>
          <Box sx={styles.otpBox}>
            {otpIterations.map((_, index) => (
              <DefaultSkeletton key={index} loading={loading}>
                <FormControl
                  key={index}
                  sx={customStyles}
                  error={showError}
                  fullWidth={fullWidth}
                  variant="outlined">
                  <InputBase
                    inputRef={index === focusIndex ? ref : field.ref}
                    type={type}
                    value={state[index]}
                    onChange={(event) => {
                      console.log(event.nativeEvent['data']);
                      handleOnChange(index, event.nativeEvent['data']);
                    }}
                    onClick={() => setFocusIndex(index)}
                    onKeyUp={(event) => handleKeyDown(index, event.key)}
                    sx={{
                      ...styles.otpField,
                      borderColor: state[index] ? 'primary.main' : 'custom.scrollThumb'
                    }}
                    inputProps={inputProps}
                    error={showError}
                    onPaste={handlePaste}
                    {...rest}
                  />
                </FormControl>
              </DefaultSkeletton>
            ))}
          </Box>
        </Box>
      )}
      {...rest}
    />
  );
};

export default Otp;
