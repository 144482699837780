import { Box } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { supplier } from '../../../redux/WarehouseSlice/supplierSlice';
import { Form, FormWrapper } from '../../common';
import CreateSuppliers from './CreateSuppliers';

const Suppliers = () => {
  const supplierData = useSelector((state) => state.supplier);

  const { control, trigger, getValues } = useForm({
    defaultValues: supplierData,
    mode: 'all',
    criteriaMode: 'all'
  });

  const dispatch = useDispatch();

  const forms = [
    {
      label: 'Create Suppliers',
      form: CreateSuppliers
    }
  ];

  return (
    <FormWrapper
      heading="Manage Suppliers"
      buttonLabel="Next"
      buttonClick={() => trigger().then((res) => res && dispatch(supplier(getValues())))}>
      {forms.map(({ label, form: FormRenderer }, index) => {
        return (
          <Box key={index}>
            <Form heading={label}>
              <FormRenderer control={control} />
            </Form>
          </Box>
        );
      })}
    </FormWrapper>
  );
};

export default Suppliers;
