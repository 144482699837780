import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BAD_REQUEST, SUCCESS } from '../../../../../constants/apiStatusCodes';
import { ERROR, SUCCESS as SUCCESS_MSG } from '../../../../../constants/constants';
import { createNewUser } from '../../../../../redux/APICaller/userPrivilegeAPI';
import { createUser } from '../../../../../redux/createUserSlice';
import { showToastMessage } from '../../../../../redux/showMessageSlice';
import { Button, Dropdown, Input, MainContent, Password, Radio } from '../../../../common';
import {
  emailValidator,
  passwordErrors,
  passwordMapping,
  passwordValidator,
  phoneNumberValidator,
  required,
  status
} from '../constants';
import styles from './styles';

const CreateUser = () => {
  const [showUserGroup, setShowUserGroup] = useState(false);
  const [general, setGeneral] = useState(true);
  const [userGroups, setUserGroups] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.createUser);

  const userId = user?.userId;

  const {
    control,
    trigger,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: user,
    mode: 'all',
    criteriaMode: 'all'
  });

  const creatingUser = async () => {
    const response = await createNewUser(getValues());
    if (response.status === SUCCESS) {
      setShowUserGroup(true);
      const newUser = getValues();
      dispatch(createUser({ ...newUser, userId: response?.data?.user_id }));
      dispatch(showToastMessage({ messageType: SUCCESS_MSG, message: response?.data?.message }));
    } else {
      if (response?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: response?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
  };

  return (
    <MainContent heading="User List">
      <Box sx={styles.actionWrapper}>
        <Box sx={styles.actionButtonWrapper}>
          <Box
            sx={{
              ...styles.actionButton,
              backgroundColor: general ? '#FFEFD3' : '#F4F4F4',
              border: general ? '1px solid #FFAA1B' : 'none'
            }}
            onClick={() => {
              setGeneral(true);
              setUserGroups(false);
              navigate('/create-user');
            }}>
            General
          </Box>
          {user?.userId && (
            <Box
              sx={{
                ...styles.actionButton,
                backgroundColor: userGroups ? '#FFEFD3' : '#F4F4F4',
                border: userGroups ? '1px solid #FFAA1B' : 'none'
              }}
              onClick={() => {
                setUserGroups(true);
                setGeneral(false);
                navigate(`/user-groups/${user?.userId}/assign`);
              }}>
              User Groups
            </Box>
          )}
        </Box>
        <Button
          label="Create"
          disabled={userId}
          onClick={() => trigger().then((res) => res && creatingUser())}
        />
      </Box>
      <Box sx={styles.wrapper}>
        <Box>
          <Box sx={styles.headerWrapper}>
            <Box sx={styles.heading}>User account information</Box>
          </Box>
          <Box sx={styles.inputWrapper}>
            <Box sx={styles.input}>
              <Input
                name="email"
                label="Email"
                control={control}
                disabled={userId}
                isRequired
                rules={{
                  required,
                  pattern: emailValidator
                }}
              />
            </Box>
            <Box sx={styles.input}>
              <Password
                name="password"
                label="Password"
                showEye
                disabled={userId}
                errors={errors}
                control={control}
                isRequired
                rules={{
                  required,
                  validate: passwordValidator
                }}
                errorMapping={passwordMapping}
              />
            </Box>
            <Box sx={styles.input}>
              <Password
                name="confirm_password"
                label="Confirm Password"
                showEye
                disabled={userId}
                errors={errors}
                control={control}
                isRequired
                rules={{
                  required,
                  validate: {
                    isSame: (value) =>
                      (value === getValues('password') && !!value) || passwordErrors.isSame
                  }
                }}
                errorMapping={[{ key: 'isSame', label: 'Matched' }]}
              />
            </Box>
            <Box sx={styles.input}>
              <Dropdown
                name="language"
                label="Language"
                control={control}
                isRequired
                disabled
                options={[{ label: 'English', value: 'english' }]}
              />
            </Box>
            <Box sx={styles.input}>
              <Box>
                Status{' '}
                <Box component="span" sx={styles.colorRed}>
                  *
                </Box>
              </Box>
              <Radio
                name="status"
                control={control}
                options={status}
                isDisabled={userId}
                customStyles={styles.modalRadio}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={styles.contactInfo}>
          <Box sx={styles.heading}>Contact information</Box>
          <Box sx={styles.inputWrapper}>
            <Box sx={styles.input}>
              <Input
                name="first_name"
                label="First Name"
                disabled={userId}
                control={control}
                isRequired
                rules={{ required }}
              />
            </Box>
            <Box sx={styles.input}>
              <Input
                name="last_name"
                label="Last Name"
                disabled={userId}
                control={control}
                isRequired
                rules={{ required }}
              />
            </Box>
            <Box sx={styles.input}>
              <Input
                name="phone_number"
                label="Phone Number"
                disabled={userId}
                control={control}
                isRequired
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                rules={{
                  required,
                  pattern: phoneNumberValidator
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </MainContent>
  );
};

export default CreateUser;
