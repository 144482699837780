import { Box } from '@mui/material';
import React from 'react';
import { Input, Radio } from '../../../common';
import styles from '../styles';

const Category = ({ control }) => {
  return (
    <Box sx={styles.formWrapper}>
      <Input
        name="categoryName"
        label="Category Name"
        control={control}
        customStyles={styles.input}
      />
      <Radio
        name="categoryStatus"
        label="Status"
        isRequired
        control={control}
        customStyles={styles.radioButton}
        options={[
          {
            label: 'Enable',
            value: 'enable'
          },
          {
            label: 'Disable',
            value: 'disable'
          }
        ]}
      />
    </Box>
  );
};

export default Category;
