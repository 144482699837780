import { Box } from '@mui/material';
import React from 'react';
import { Input } from '../../../common';
import styles from '../styles';

const ContactInformation = ({ control }) => {
  return (
    <Box sx={styles.formWrapper}>
      <Input name="firstName" label="First Name" control={control} customStyles={styles.input} />
      <Input name="lastName" label="Last Name" control={control} customStyles={styles.input} />
      <Input
        name="phoneNumber"
        label="Phone Number"
        control={control}
        customStyles={styles.input}
      />
      <Input name="fullAddress" label="Full Address" control={control} />
    </Box>
  );
};

export default ContactInformation;
