import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box } from '@mui/material';
import React from 'react';
import { ERROR_MSG, SUCCESS_MSG } from '../../../constants/constants';
import styles from './styles';

const ShowMessage = ({ type, message = '', customStyles }) => {
  return (
    <>
      {(type === SUCCESS_MSG || type === ERROR_MSG) && message && (
        <Box
          sx={{
            ...styles.wrapper,
            color: type === SUCCESS_MSG ? '#04AA6D' : 'error.main',
            ...customStyles
          }}>
          {type === SUCCESS_MSG ? <CheckCircleOutlineIcon /> : <ErrorOutlineIcon />}
          {message}
        </Box>
      )}
    </>
  );
};

export default ShowMessage;
