import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { errorMessage } from '../../../../constants/errorMessage';
import regex from '../../../../constants/regex';
import { userResetPassword, usersetupPassword } from '../../../../redux/APICaller/authAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { DynamicButton, Password } from '../../../common';
import { SUCCESS, INTERNAL_SERVER_ERROR } from '../../../../constants/apiStatusCodes';
import { ERROR_MESSAGE, SUCCESS_MSG, ERROR_MSG } from '../../../../constants/constants';

import styles from '../styles';

const SetUpPassword = ({ title }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { password: passwordErrMsg } = errorMessage;
  const { password: passwordRegex } = regex;

  const {
    control,
    formState: { errors },
    trigger,
    getValues
  } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: ''
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const dispatch = useDispatch();
  const { token, email, id } = useParams();
  const setupPassword = useMutation({
    mutationFn: (data) =>
      location?.pathname?.includes('/setup-password/')
        ? usersetupPassword(data)
        : userResetPassword(data),
    onSuccess: (res) => {
      if (res?.status === SUCCESS) {
        if (res?.data?.message)
          dispatch(showToastMessage({ messageType: SUCCESS_MSG, message: res?.data?.message }));
        navigate(`/sign-in`);
      } else if (res?.status !== INTERNAL_SERVER_ERROR) {
        dispatch(
          showToastMessage({ messageType: ERROR_MSG, message: res?.data?.message || ERROR_MESSAGE })
        );
      }
    },
    onError: () => {
      dispatch(showToastMessage({ messageType: ERROR_MSG, message: ERROR_MESSAGE }));
    }
  });

  const handleClick = (res) => {
    if (res) {
      const { password, confirmPassword } = getValues();
      setupPassword.mutate(
        location?.pathname?.includes('/setup-password/')
          ? {
              code: id,
              password: password,
              confirm_password: confirmPassword
            }
          : {
              email_address: email,
              reset_token: token,
              new_password: password,
              reset_new_password: confirmPassword
            }
      );
    }
  };

  return (
    <Box sx={styles.wrapper}>
      <Box>
        <Box sx={styles.heading}>{title}</Box>
        <Box sx={styles.description}>Loren epsom simply dummy text for text use.</Box>
      </Box>
      <Box sx={styles.inputWrapper}>
        <Password
          name="password"
          label="Password"
          placeholder="Password"
          loading={setupPassword.isPending}
          customStyles={styles.input}
          startIcon={<LockOutlinedIcon />}
          control={control}
          errors={errors}
          rules={{
            required: 'This is a required field',
            validate: {
              isLength: (value) => (value && value.length >= 6) || passwordErrMsg.isLength,
              isUpper: (value) =>
                (value && passwordRegex.isUpper.test(value.toString())) || passwordErrMsg.isUpper,
              isLower: (value) =>
                (value && passwordRegex.isLower.test(value.toString())) || passwordErrMsg.isLower,
              isNum: (value) =>
                (value && passwordRegex.isNum.test(value.toString())) || passwordErrMsg.isNum
            }
          }}
        />
        <Password
          name="confirmPassword"
          label="Confirm Password"
          placeholder="Confirm Password"
          loading={setupPassword.isPending}
          customStyles={styles.input}
          startIcon={<LockOutlinedIcon />}
          control={control}
          errors={errors}
          rules={{
            required: 'This is a required field',
            validate: {
              isSame: (value) =>
                (value === getValues('password') && !!value) || passwordErrMsg.isSame
            }
          }}
        />
      </Box>
      <DynamicButton
        label="Confirm"
        customStyles={styles.button}
        disabled={setupPassword.isPending}
        onClick={() => trigger().then((res) => handleClick(res))}
      />
    </Box>
  );
};

export default SetUpPassword;
