const styles = {
  wrapper: {
    transition: 'all 300ms ease',
    border: '2px solid',
    borderColor: 'primary.main',
    color: 'primary.main',
    pointerEvents: 'all',
    fontSize: {
      xs: '14px',
      sm: '16px'
    },
    minWidth: {
      xs: '70px',
      sm: '100px'
    },
    textTransform: 'none',
    padding: '5px 30px',
    '&:hover': {
      border: '2px solid',
      borderColor: 'primary.main',
      backgroundColor: 'primary.main',
      color: 'white'
    },

    '&.MuiButton-root.Mui-disabled': {
      cursor: 'not-allowed',
      backgroundColor: 'transparent',
      border: '2px solid rgba(0, 0, 0, 0.18)',
      color: 'rgba(0, 0, 0, 0.18)',
      pointerEvents: 'all'
    }
  }
};

export default styles;
