import {
  Facility,
  ForgotPassword,
  GettingStarted,
  SetUpPassword,
  SignIn,
  SignUp,
  Suppliers,
  UserProfile,
  Verify,
  Warehouse
} from '../components/features';
import {
  AcceptConsignment,
  AfterShipmentAdded,
  AllOrders,
  AllowGateIn,
  AllowGateOut,
  BundleOrderView,
  BundlingAllOrders,
  BundlingConfirmPicking,
  CompletedManifestDetals,
  CompleteLoadingUnloading,
  CompletePutlist,
  ConfirmPutaway,
  ConfirmPutlist,
  CreateBundleOrder,
  CreateIRN,
  CreateManualACN,
  CreateProduct,
  CreatePutlist,
  CreateReturnOrder,
  CreateTransfer,
  CreateUser,
  DefineBundle,
  FindBundle,
  FindConsignment,
  FindOrders,
  FindProduct,
  FindPutlist,
  FindReturnOrder,
  GenerateLots,
  GenerateReturnOrder,
  InboundCreateProduct,
  Issue,
  ManageUser,
  ManageUserGroup,
  Manifest,
  OrderView,
  OutboundCreateSO,
  Overview,
  Pack,
  PackagingIssuance,
  PendingPutaway,
  Pick,
  PickingDetails,
  PickingDetailsPackaging,
  PickTransfer,
  ProductLookUp,
  PutawayTransfer,
  PutBack,
  Receive,
  ReceiveIRN,
  ReceiveIRNComplete,
  ReceiveManifest,
  ReceiveOnDock,
  ReleaseFromDock,
  ReturnCreateRO,
  ReturnManifest,
  ReturnManifestDetails,
  ReturnManifestMain,
  SearchACN,
  SearchInventory,
  SearchStockTransfer,
  StagingInventory,
  StartLoadingUnloading,
  UserGroups,
  ViewConsignment,
  ViewIRN,
  ViewOrders,
  ViewProduct,
  ViewPutlist,
  ViewReturnOrder,
  ViewTransfer
} from '../components/Pages';
import ReceiveTrailerOnDock from '../components/Pages/ReceiveOnDock/ReceiveTrailerOnDock';
import UpdateUser from '../components/Pages/UserPrivilege/ManageUser/UpdateUser';

const router = {
  authentication: [
    {
      path: '/sign-in',
      element: SignIn
    },
    {
      path: '/sign-up',
      element: SignUp
    },
    {
      path: '/forgot-password',
      element: ForgotPassword
    },
    {
      path: '/verify/:id',
      element: Verify
    },
    {
      path: '/reset-password/:email/:token',
      element: SetUpPassword
    },
    {
      path: '/setup-password/:id',
      element: SetUpPassword
    }
  ],
  defaultRoutes: [
    {
      path: '/',
      element: Overview
    },
    {
      path: '/getting-started',
      element: GettingStarted
    },
    {
      path: '/user-profile',
      element: UserProfile
    },
    {
      path: '/facility',
      element: Facility
    },
    {
      path: '/warehouse',
      element: Warehouse
    },
    {
      path: '/suppliers',
      element: Suppliers
    },
    {
      path: 'search-acn',
      element: SearchACN
    },
    {
      path: 'search-acn/accept/:acnId',
      element: AcceptConsignment
    },
    {
      path: 'search-acn/allow-gate-in/:acnId',
      element: AllowGateIn
    },
    {
      path: 'create-manual-acn',
      element: CreateManualACN
    },
    {
      path: 'search-acn/details/:acnId',
      element: 'Page Coming Soon...'
    },
    {
      path: 'receive-on-dock',
      element: ReceiveOnDock
    },
    {
      path: 'allow-gate-out',
      element: AllowGateOut
    },
    {
      path: 'receive-on-dock/confirm-receive-on-dock/:acnId',
      element: ReceiveTrailerOnDock
    },
    {
      path: 'start-loading-unloading',
      element: StartLoadingUnloading
    },
    {
      path: 'complete-loading-unloading',
      element: CompleteLoadingUnloading
    },
    {
      path: 'release-from-dock',
      element: ReleaseFromDock
    },
    {
      path: 'view-orders/:po_no?',
      element: ViewOrders
    },
    {
      path: 'find-orders',
      element: FindOrders
    },
    {
      path: 'view-consignment/:acn_no?',
      element: ViewConsignment
    },
    {
      path: 'find-consignment',
      element: FindConsignment
    },
    {
      path: 'find-return-order',
      element: FindReturnOrder
    },
    {
      path: 'view-return-order/:ro_no?',
      element: ViewReturnOrder
    },
    {
      path: 'create-return-order',
      element: CreateReturnOrder
    },
    {
      path: 'create-irn',
      element: CreateIRN
    },
    {
      path: 'receive-irn',
      element: ReceiveIRN
    },
    {
      path: 'create-return-order/:irnId',
      element: GenerateReturnOrder
    },
    {
      path: 'receive-irn/:skuCode/:irnId',
      element: ProductLookUp
    },
    {
      path: 'view-irn',
      element: ViewIRN
    },
    {
      path: 'receive-irn-complete/:irn/:irnId/:wid/:qcId',
      element: ReceiveIRNComplete
    },
    {
      path: 'create-product',
      element: CreateProduct
    },
    {
      path: 'find-product',
      element: FindProduct
    },
    {
      path: 'view-product/:sku_id?',
      element: ViewProduct
    },
    {
      path: 'search-inventory',
      element: SearchInventory
    },
    {
      path: 'create-putlist',
      element: CreatePutlist
    },
    {
      path: 'complete-putlist',
      element: CompletePutlist
    },
    {
      path: 'find-putlist',
      element: FindPutlist
    },
    {
      path: 'view-putlist/:putlistId?',
      element: ViewPutlist
    },
    {
      path: 'create-transfer',
      element: CreateTransfer
    },
    {
      path: 'view-transfer/:transferId?',
      element: ViewTransfer
    },
    {
      path: 'pick-transfer',
      element: PickTransfer
    },
    {
      path: 'search-transfer',
      element: SearchStockTransfer
    },
    {
      path: 'put-away-transfer',
      element: PutawayTransfer
    },
    {
      path: 'create-purchase-order',
      element: InboundCreateProduct
    },
    {
      path: 'all-orders',
      element: AllOrders
    },
    {
      path: 'all-orders/:order_id?/:order_no?/:channel?',
      element: OrderView
    },
    {
      path: 'create-so',
      element: OutboundCreateSO
    },
    {
      path: 'pick',
      element: Pick
    },
    {
      path: 'pick-details/:id/:picklist_no',
      element: PickingDetails
    },
    {
      path: 'pack',
      element: Pack
    },
    {
      path: 'manifest',
      element: Manifest
    },
    {
      path: 'manifest-details/:id',
      element: CompletedManifestDetals
    },
    {
      path: 'return-manifest',
      element: ReturnManifest
    },
    {
      path: 'create-ro',
      element: ReturnCreateRO
    },
    {
      path: 'return-manifest-details/:id',
      element: ReturnManifestDetails
    },
    {
      path: 'receive-return-manifest/:id',
      element: ReturnManifestMain
    },
    {
      path: 'receive-return-manifest-details/:id/:uuid',
      element: AfterShipmentAdded
    },
    {
      path: 'staging-inventory',
      element: StagingInventory
    },
    {
      path: 'put-back',
      element: PutBack
    },
    {
      path: 'receive-manifest',
      element: ReceiveManifest
    },
    {
      path: 'bundle-details',
      element: FindBundle
    },
    {
      path: 'bundle-create',
      element: DefineBundle
    },
    {
      path: 'all-bundling-orders',
      element: BundlingAllOrders
    },
    {
      path: 'create-bundle-order',
      element: CreateBundleOrder
    },
    {
      path: 'bundle-order-view/:id',
      element: BundleOrderView
    },
    {
      path: 'issue',
      element: Issue
    },
    {
      path: 'confirm-picking/:id',
      element: BundlingConfirmPicking
    },
    {
      path: 'receive',
      element: Receive
    },
    {
      path: 'confirm-putaway/:id',
      element: ConfirmPutaway
    },
    {
      path: 'pending-putaway/:id',
      element: PendingPutaway
    },
    {
      path: 'generate-lot',
      element: GenerateLots
    },
    {
      path: 'issue-to-sbs',
      element: PackagingIssuance
    },
    {
      path: 'confirm-putlist/:id',
      element: ConfirmPutlist
    },
    {
      path: 'picking-details-packaging/:id',
      element: PickingDetailsPackaging
    },
    {
      path: 'manage-user-group',
      element: ManageUserGroup
    },
    {
      path: 'manage-users',
      element: ManageUser
    },
    {
      path: '/create-user',
      element: CreateUser
    },
    {
      path: '/update-user/:id',
      element: UpdateUser
    },
    {
      path: '/user-groups/:id/:type',
      element: UserGroups
    }
  ]
};

const { authentication, defaultRoutes } = router;

export { authentication, defaultRoutes };
