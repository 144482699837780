import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Button, IconButton, Switch } from '@mui/material';
import React, { useState } from 'react';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import { Spinner, TableDataRenderer } from '../../../../common';
import styles from '../styles';

const capitalizeFirstLetter = (inputString) => {
  return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
};

const ManageUserGroupTable = ({
  list = [],
  isLoading,
  deleteDisable,
  deleteUserGroup,
  editUserGroup
}) => {
  const [{ width }] = useWindowDimensions();
  const isWebView = width > 1000;

  const [toggleStates, setToggleStates] = useState({});

  const handleToggle = (userGroup) => {
    setToggleStates((prevState) => ({
      ...prevState,
      [userGroup]: !prevState[userGroup]
    }));
  };

  const columnDefs = [
    {
      field: 'UserGroup',
      headerName: 'User Group',
      // headerCheckboxSelection: true,
      // checkboxSelection: true,
      flex: isWebView ? 1 : 0
    },
    {
      field: 'Type',
      headerName: 'Type',
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => <Box>{capitalizeFirstLetter(data?.Type)}</Box>
    },
    {
      field: 'Status',
      headerName: 'Status',
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => (
        <Button
          sx={{
            backgroundColor: data?.Status === 'ENABLE' ? '#E4FFD8' : '#FFF3D8',
            color: data?.Status === 'ENABLE' ? '#32830E' : '#BE870D',
            width: 80,
            '&:hover': {
              backgroundColor: data?.Status === 'ENABLE' ? '#E4FFD8' : '#FFF3D8',
              color: data?.Status === 'ENABLE' ? '#32830E' : '#BE870D'
            }
          }}>
          {data?.Status}
        </Button>
      )
    },
    {
      field: 'Action',
      headerName: 'Action',
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => (
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          {/* <Switch
            checked={toggleStates[data.UserGroup]}
            onChange={() => handleToggle(data.UserGroup)}
            name={`toggle-${data.UserGroup}`}
          /> */}
          <IconButton onClick={() => editUserGroup(data)}>
            <EditOutlinedIcon />
          </IconButton>
          <IconButton disabled={deleteDisable} onClick={() => deleteUserGroup(data)}>
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
      )
    }
  ];

  return (
    <Box sx={styles.wrapper}>
      <Box className="ag-theme-alpine" sx={styles.tableWrapper}>
        {isLoading ? <Spinner /> : <TableDataRenderer list={list} columnDefs={columnDefs} />}
      </Box>
    </Box>
  );
};

export default ManageUserGroupTable;
