import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BAD_REQUEST, SUCCESS } from '../../../../constants/apiStatusCodes';
import { consigmentType } from '../../../../constants/commonLabels';
import { ERROR, SUCCESS as SUCCESS_MSG } from '../../../../constants/constants';
import { required } from '../../../../constants/errorMessage';
import { receiveTrailerOnDock } from '../../../../constants/formData';
import { trailerStatus } from '../../../../constants/messages';
import { getAcceptConsignmentDetails } from '../../../../redux/APICaller';
import {
  assignDock,
  getDockList,
  getInvoiceDetails
} from '../../../../redux/APICaller/receiveOnDockAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { Button, Input, MainContent } from '../../../common';
import CustomPopover from '../../../common/PopOver';
import CustomDropdownHandler from './CustomDropdownHandler';
import styles from './styles';

const showInvoiceDetails = (invoiceData) => {
  if (!invoiceData) return null;
  return (
    <Box sx={styles.invoiceDetailsWrapper}>
      <Box sx={styles.invoiceHeading}>Invoice Details</Box>
      <Box sx={styles.detailsWrapper}>
        {invoiceData?.map(({ invoiceNumber, shipToCity, noOfUnits, name }, index) => {
          return (
            <Box key={`${invoiceNumber + '-' + index}`}>
              <Box sx={styles.detailsHeading}>{`${index + 1}. ${invoiceNumber}`}</Box>
              <Box sx={styles.details}>
                <Box sx={styles.singleDetail}>
                  <Box>Name</Box>
                  <Box>{name}</Box>
                </Box>
                <Box sx={styles.singleDetail}>
                  <Box>Ship to city</Box>
                  <Box>{shipToCity}</Box>
                </Box>
                <Box sx={styles.singleDetail}>
                  <Box>Quantity</Box>
                  <Box>{noOfUnits}</Box>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

const ReceiveTrailerOnDock = () => {
  const dataKeyNameChange = useMemo(
    () => (arrayOfObj) => {
      const newArrayOfObj = arrayOfObj?.map(({ dockCode: label, dock_id: value, ...rest }) => ({
        label,
        value,
        ...rest
      }));

      return newArrayOfObj;
    },
    []
  );

  const [formData, setFormData] = useState(receiveTrailerOnDock);
  const [invoiceData, setInvoiceData] = useState([]);
  const params = useParams();
  const dispatch = useDispatch();
  const [defultVal, setDefultVal] = useState('');
  const [data, setData] = useState({});
  const [confirm, setConfirm] = useState(false);

  const { control, trigger, watch, setValue } = useForm({
    mode: 'all'
  });

  const fetchInvoiceDetails = useCallback(
    async (tripId) => {
      const res = await getInvoiceDetails({ tripId });
      if (res?.status == SUCCESS) {
        setInvoiceData(res.data);
      } else {
        if (res?.status === BAD_REQUEST)
          dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
        else dispatch(showToastMessage({ messageType: ERROR }));
      }
    },
    [dispatch, setInvoiceData, params]
  );

  const APICALL = useCallback(async () => {
    const res = await getAcceptConsignmentDetails({
      acn_no: params?.acnId
    });
    if (res.status === SUCCESS) {
      if (res.data.consignment_type !== consigmentType.inBound) {
        const tempFormField = [...formData];
        tempFormField[3].hide = false;
        setFormData(tempFormField);
        fetchInvoiceDetails(res?.data?.trip_id);
      }

      setTimeout(() => {
        setValue('truckNumber', res.data.truck_number);
        setValue('consignmentType', res.data.consignment_type);
        if (res.data.consignment_type) setValue('numberOfInvoice', res.data.no_of_invoices);
      }, 100);

      setData(res?.data);
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
  }, [
    dispatch,
    setFormData,
    setValue,
    setData,
    getAcceptConsignmentDetails,
    fetchInvoiceDetails,
    params,
    formData,
    invoiceData
  ]);

  const fetchDockDropList = useCallback(async () => {
    const res = await getDockList({ acn_no: params?.acnId });
    if (res?.status == SUCCESS) {
      const tempFields = [...formData];
      tempFields[2].options = dataKeyNameChange(res?.data);
      setDefultVal(res?.data[0].dock_id);
      setValue('chooseDock', res?.data[0].dock_id);

      setTimeout(() => {
        setFormData(tempFields);
      });
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
  }, [dispatch, setDefultVal, setValue, setFormData, getDockList, params]);

  useEffect(() => {
    APICALL();
    fetchDockDropList();
  }, []);

  /**
   * Functions to call API and Validate fields
   */

  const assignDockApiCall = useCallback(async () => {
    const res = await assignDock({
      truck_number: watch('truckNumber'),
      dock_id: watch('chooseDock'),
      trip_id: data?.trip_id
    });
    if (res.status === SUCCESS) {
      setConfirm(true);
      dispatch(
        showToastMessage({
          messageType: SUCCESS_MSG,
          message: res?.data?.message || trailerStatus
        })
      );
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
  }, [dispatch, showToastMessage, assignDock, watch, data]);

  const finishExecution = useCallback(
    (res) => {
      if (res) {
        assignDockApiCall();
      }
    },
    [assignDockApiCall]
  );

  const triggerValidation = useCallback(() => {
    trigger().then((res) => finishExecution(res));
  }, [trigger, finishExecution]);

  return (
    <MainContent heading="Trailer Information" description="List of trailers waiting in bay">
      <Box component="form">
        <Box sx={styles.wrapper}>
          <Box>
            {formData?.map(
              (
                {
                  maxLength,
                  disabled = false,
                  name,
                  label,
                  type,
                  options,
                  isRequired = false,
                  inputType = 'text',
                  hide
                },
                index
              ) => {
                if (hide) return null;
                return (
                  <Box key={`${name + '-' + index}`} sx={styles.tableWrapper}>
                    <Box sx={styles.label}>{label}</Box>
                    <Box sx={styles.inputWrapper}>
                      {type === 'input' ? (
                        <Box sx={styles.input}>
                          <Input
                            disabled={disabled}
                            maxLength={maxLength}
                            name={name}
                            placeholder={label}
                            type={inputType}
                            control={control}
                            rules={{
                              required: {
                                value: isRequired,
                                message: required
                              }
                            }}
                          />
                          {name === 'numberOfInvoice' && (
                            <Box sx={styles.invoiceDetailsWeb}>
                              <CustomPopover
                                label="Show Invoice Details"
                                customStyles={styles.customPopoverHeight}>
                                {showInvoiceDetails(invoiceData)}
                              </CustomPopover>
                            </Box>
                          )}
                        </Box>
                      ) : (
                        <CustomDropdownHandler
                          defultVal={defultVal}
                          name={name}
                          label={label}
                          options={options}
                          control={control}
                          inputType={inputType}
                          rules={{
                            required: {
                              value: isRequired,
                              message: required
                            }
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                );
              }
            )}
          </Box>
          <Box sx={styles.invoiceDetailsMobile}>
            <CustomPopover label="Show Invoice Details">
              {showInvoiceDetails(invoiceData)}
            </CustomPopover>
          </Box>
          {!confirm && (
            <Box sx={styles.buttonWrapper}>
              <Button label="Confirm receive on dock" onClick={triggerValidation} />
            </Box>
          )}
          {confirm && (
            <Box sx={styles.description}>
              <CheckCircleOutlineIcon sx={styles.icon} />
              Trailer status updated
            </Box>
          )}
        </Box>
      </Box>
    </MainContent>
  );
};

export default ReceiveTrailerOnDock;
