import { Box } from '@mui/material';
import React from 'react';
import { AlertMessage } from '../../components/common';
import styles from './styles';

const AuthLayout = ({ children }) => {
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.imageWrapper}>
        <Box component="img" src="/warehouse.png" alt="warehouse" sx={styles.image} />
      </Box>
      <Box sx={styles.children}>{children}</Box>
      <Box sx={styles.logoWrapper}>
        <Box component="img" src="/losungLogo.png" alt="losung_logo" sx={styles.logo} />
      </Box>
      <AlertMessage />
    </Box>
  );
};

export default AuthLayout;
