const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    widht: '100%',
    position: 'relative',
    boxSizing: 'border-box'
  },

  imageWrapper: {
    display: {
      xs: 'none',
      md: 'flex'
    },
    justifyContent: 'center',
    alignItems: 'center',
    width: '65%',
    height: '100%'
  },

  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },

  children: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: {
      xs: '100%',
      md: '35%'
    },
    height: '100%'
  },

  logoWrapper: {
    position: 'absolute',
    top: 10,
    right: 10
  },

  logo: {
    width: {
      xs: '100px',
      sm: '150px',
      md: '200px'
    }
  }
};

export default styles;
