import { Box } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BAD_REQUEST, SUCCESS } from '../../../../constants/apiStatusCodes';
import { ERROR, SUCCESS as SUCCESS_MSG } from '../../../../constants/constants';
import { GetUserList, UpdateBulkUserStatus } from '../../../../redux/APICaller/userPrivilegeAPI';
import { deleteUser } from '../../../../redux/createUserSlice';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { getFormattedDate } from '../../../../utils';
import { Button, Dropdown, MainContent } from '../../../common';
import ManageUserTable from './ManageUserTable';
import styles from './styles';

const dataKeyNameChange = (arrayOfObj, page) => {
  const newArrayOfObj = arrayOfObj?.map(
    (
      {
        firstName,
        lastName,
        email: Email,
        last_login_time: LastLogin,
        phone,
        prefix,
        enabled,
        ...rest
      },
      index
    ) => ({
      ID: 15 * (page - 1) + ++index,
      Name: `${firstName} ${lastName}`,
      Phone: `${prefix}-${phone}`,
      Email,
      LastLogin: getFormattedDate(LastLogin, 'DD/MM/YYYY HH:MM') || 'No Data',
      Status: enabled ? 'Enable' : 'Disable',
      ...rest
    })
  );
  return newArrayOfObj;
};

const ManageUser = () => {
  const navigate = useNavigate();

  const { control, getValues, watch } = useForm({
    defaultValues: {
      status: ''
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [unitSelectedList, setUnitSelectedList] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [createPutlistLoading, setCreatePutlistLoading] = useState(false);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 0
  });

  const dispatch = useDispatch();

  const res = {
    status: 200,
    total_elements: 2,
    total_pages: 1,
    data: {
      list: [
        {
          id: '17b06ca1-903a-4420-80a8-5504c05db047',
          firstName: 'Hell',
          lastName: 'She',
          email: '34frfd@blalac.sbi',
          prefix: '+91',
          phone: '9999911111',
          last_login_time: '12/01/2024 10:29',
          enabled: true
        },
        {
          id: '2501a2c5-1a45-44a4-aabe-5054fb495df8',
          firstName: 'Mandeep',
          lastName: 'Drall',
          email: 'mandeep@losung360.com',
          prefix: '+91',
          phone: '6744582340',
          last_login_time: '12/01/2024 10:29',
          enabled: false
        },
        {
          id: '2cd36003-2a63-4aff-ac0a-e417e5391c58',
          firstName: 'Hell',
          lastName: 'She',
          email: 'mastersdsd@blala',
          prefix: '+91',
          phone: '9999911111',
          last_login_time: '12/01/2024 10:29',
          enabled: true
        },
        {
          id: '43f068f9-91a0-4f4f-9f1f-aea450dbffec',
          firstName: 'Bill',
          lastName: 'Gates',
          email: 'test223@sdsd.com',
          prefix: '+91',
          phone: '9999901111',
          last_login_time: '12/01/2024 10:29',
          enabled: false
        },
        {
          id: '631ceed4-1ef1-4f16-b346-f3c58089c0cf',
          firstName: 'Lalit',
          lastName: 'Seth',
          email: 'lalit@losung360.com',
          prefix: '+91',
          phone: '7988383730',
          last_login_time: '12/01/2024 10:29',
          enabled: false
        },
        {
          id: '23',
          firstName: 'Shubham',
          lastName: 'Srivastava',
          email: 'example@gmail.com',
          last_login_time: '12/01/2024 10:29',
          prefix: '+91',
          phone: '9145343478',
          enabled: true
        },
        {
          id: '13',
          firstName: 'Shubham Srivastava',
          lastName: 'Srivastava',
          email: 'example@gmail.com',
          last_login_time: '12/01/2024 10:29',
          prefix: '+91',
          phone: '9145343478',
          enabled: false
        }
      ]
    }
  };

  const APICALL = async (page = 1) => {
    setIsLoading(true);

    const res = await GetUserList({
      page: page - 1,
      limit: 15
    });

    if (res.status === SUCCESS) {
      if (page === 1) {
        const newData = dataKeyNameChange(res?.data?.list, page);
        setList([{ page: 1, array: newData }]);
      } else setList([...list, { page, array: dataKeyNameChange(res?.data?.list, page) }]);
      setPageData({ maxPage: res.data.total_pages, currentPage: page });
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
    setIsLoading(false);
  };

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    APICALL();
  }, []);

  const pageClick = useCallback((_, pageNumber) => {
    apiCallWithPage(pageNumber);
  }, []);

  const setNewListWithPage = (page, newlist) => {
    if (page) {
      const newListobj = list?.map((item) => {
        if (item.page === page) {
          return { ...item, array: newlist };
        }
        return item;
      });

      setList(newListobj);
    }
  };

  const updateStatusInList = (ids, status) => {
    const newList = [];
    tableRows?.forEach((item) => {
      let found = false;

      ids?.forEach((id) => {
        if (item?.id.toString() === id.toString()) {
          found = true;
        }
      });
      if (!found) {
        newList?.push(item);
      } else {
        newList?.push({ ...item, Status: status ? 'Enable' : 'Disable' });
      }
    });
    setTableRows(newList);
  };

  const updateStatusApi = async (ids) => {
    setCreatePutlistLoading(true);
    const customPayload = { user_ids: ids, enabled: getValues('status') };
    const res = await UpdateBulkUserStatus({
      ...customPayload
    });
    if (res.status === SUCCESS) {
      dispatch(
        showToastMessage({
          messageType: SUCCESS_MSG,
          message: res?.data?.message || res?.data?.msg
        })
      );
      updateStatusInList(ids, getValues('status'));
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR }));
    }
    setCreatePutlistLoading(false);
  };

  const createPutbackWithIds = () => {
    const ids = unitSelectedList?.map((row) => {
      return row?.id;
    });

    updateStatusApi(ids);
  };

  useEffect(() => {
    setUnitSelectedList([]);
  }, []);

  useEffect(() => {
    if (watch('status').toString()) createPutbackWithIds();
  }, [watch('status')]);

  return (
    <MainContent heading="User List">
      <Box sx={styles.actionWrapper}>
        {unitSelectedList?.length > 0 ? (
          <Box sx={styles.actionInput}>
            <Dropdown
              name="status"
              label="Status"
              control={control}
              options={[
                { label: 'Enable All', value: true },
                { label: 'Disable All', value: false }
              ]}
            />
          </Box>
        ) : null}
        <Box sx={styles.newUser}>
          <Button
            label="Add New User"
            onClick={() => {
              dispatch(deleteUser());
              navigate('/create-user');
            }}
          />
        </Box>
      </Box>

      <ManageUserTable
        setUnitSelectedList={setUnitSelectedList}
        list={tableRows}
        pageData={pageData}
        pageClick={pageClick}
        isLoading={isLoading}
        page={pageData.currentPage}
      />
    </MainContent>
  );
};

export default ManageUser;
