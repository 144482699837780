const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    height: '100%',
    gap: {
      xs: '20px',
      sm: '30px'
    }
  },

  heading: {
    fontSize: {
      xs: '32px',
      sm: '36px'
    },
    fontWeight: 200,
    lineHeight: '48px'
  },

  description: {
    fontSize: {
      xs: '18px',
      sm: '22px'
    },
    fontWeight: 200,
    lineHeight: '34px',
    color: '#272020'
  },

  childrenWrapper: {
    backgroundColor: 'white',
    padding: {
      xs: '10px',
      sm: '15px 20px'
    },
    boxSizing: 'border-box',
    border: '1px solid',
    borderColor: 'custom.gray',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px'
  }
};

export default styles;
