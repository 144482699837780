import { Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DynamicButton } from '../../common';
import Form from '../../common/Form';
import styles from './styles';

const GettingStarted = () => {
  const navigate = useNavigate();
  const management = useSelector((state) => state);

  const stepsData = [
    {
      heading: 'Create Your Profile',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      imageUrl: '/user.svg',
      buttonLabel: 'Complete Profile',
      backgroundColor: '#D9FFE3',
      route: '/user-profile',
      isCompleted: management.user.isCompleted
    },
    {
      heading: 'Facility',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      imageUrl: '/facility.svg',
      buttonLabel: 'Create Facility',
      backgroundColor: '#D9F9FF',
      route: '/facility',
      isCompleted: management.facility.isCompleted
    },
    {
      heading: 'Setup Warehouse',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      imageUrl: '/garage.svg',
      buttonLabel: 'Add Warehouse',
      backgroundColor: '#146BF51F',
      route: '/warehouse',
      isCompleted: false
    },
    {
      heading: 'Create Product/Items',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      imageUrl: '/product.svg',
      buttonLabel: 'Add Items',
      backgroundColor: '#F3FFD9',
      route: '/user-profile',
      isCompleted: false
    }
  ];

  return (
    <Form heading="Getting Started" description="4 Steps to complete">
      <Box>
        {stepsData.map(
          (
            { heading, description, imageUrl, buttonLabel, backgroundColor, route, isCompleted },
            index
          ) => {
            return (
              <>
                <Box
                  key={index}
                  sx={{
                    ...styles.dataWrapper,
                    marginTop: {
                      xs: index === 0 ? 0 : '1.5em',
                      sm: index === 0 ? 0 : '2em',
                      md: index === 0 ? 0 : '2.5em'
                    },
                    marginBottom: {
                      xs: index === stepsData.length - 1 ? 0 : '1.5em',
                      sm: index === stepsData.length - 1 ? 0 : '2em',
                      md: index === stepsData.length - 1 ? 0 : '2.5em'
                    }
                  }}>
                  <Box sx={styles.headerWrapper}>
                    <Box
                      component="img"
                      src={imageUrl}
                      alt="image"
                      sx={{ ...styles.image, backgroundColor }}
                    />
                    <Box>
                      <Box sx={styles.heading}>{heading}</Box>
                      <Box sx={styles.description}>{description}</Box>
                    </Box>
                  </Box>
                  {isCompleted ? (
                    <Box
                      component="img"
                      src="/completed.svg"
                      alt="completed"
                      sx={{ marginRight: '3.5%' }}
                    />
                  ) : (
                    <DynamicButton
                      label={buttonLabel}
                      customStyles={styles.button}
                      onClick={() => navigate(route)}
                    />
                  )}
                </Box>
                {index !== stepsData.length - 1 && <Box sx={styles.divider} />}
              </>
            );
          }
        )}
      </Box>
    </Form>
  );
};

export default GettingStarted;
