import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from '../components/Dashboard';
import { Error, SuccessPage } from '../components/features';
import { authentication, defaultRoutes } from '../constants/routes';
import { AuthLayout } from '../layout';
import PrivateRoute from './PrivateRoute';

const AppRouter = () => {
  const token = localStorage.getItem('wms_access_token');
  const isLoggedIn = !!token;

  return (
    <Routes>
      {authentication?.map(({ path, element: Element }, index) => {
        return (
          <Route
            key={index}
            path={path}
            element={
              isLoggedIn ? (
                <Navigate to="/" />
              ) : (
                <AuthLayout>
                  <Element />
                </AuthLayout>
              )
            }
          />
        );
      })}

      {defaultRoutes?.map(({ path, element: Element }, index) => {
        return (
          <Route
            key={index}
            path={path}
            element={
              <PrivateRoute>
                <Dashboard>
                  <Element />
                </Dashboard>
              </PrivateRoute>
            }
          />
        );
      })}
      <Route path="/success" element={<SuccessPage />} />
      <Route path="*" element={<Error />} />
    </Routes>
  );
};

export default AppRouter;
