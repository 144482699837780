import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { warehouse as warehouseSetup } from '../../../redux/WarehouseSlice/warehouseSlice';
import { Form, FormWrapper } from '../../common';
import Category from './Category';
import CreateWarehouse from './CreateWarehouse';

const Warehouse = () => {
  const [currentForm, setCurrentForm] = useState(0);
  const warehouse = useSelector((state) => state.warehouse);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { control, trigger, getValues } = useForm({
    defaultValues: warehouse,
    mode: 'all',
    criteriaMode: 'all'
  });

  const forms = [
    {
      label: 'Create Warehouse',
      form: CreateWarehouse,
      handleNext: (res) => {
        res && setCurrentForm(currentForm + 1);
      }
    },
    {
      label: 'Create Category',
      form: Category,
      handleNext: (res) => {
        res && dispatch(warehouseSetup({ ...getValues(), isCompleted: true })),
          navigate('/suppliers');
      }
    }
  ];

  return (
    <FormWrapper
      heading="Warehouse"
      buttonClick={() => trigger().then((res) => forms[currentForm].handleNext(res))}>
      {forms?.map(({ label, form: FormRenderer }, index) => {
        return (
          <Box hidden={currentForm !== index} key={index}>
            <Form heading={label}>
              <FormRenderer control={control} />
            </Form>
          </Box>
        );
      })}
    </FormWrapper>
  );
};

export default Warehouse;
