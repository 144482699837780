import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import SimpleButton from '../SimpleButton';
import styles from './styles';

const LinkButton = ({ disabled, customStyles, ...rest }) => {
  return (
    <Box sx={{ ...styles.wrapper, ...customStyles }}>
      {disabled ? (
        <SimpleButton disabled {...rest} />
      ) : (
        <Link {...rest}>
          <SimpleButton {...rest} />
        </Link>
      )}
    </Box>
  );
};

export default LinkButton;
