const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8
  },

  heading: {
    fontSize: '16px',
    fontWeight: 600
  },

  inputWrapper: {
    marginTop: 6,
    display: 'flex',
    flexWrap: 'wrap',
    gap: 4
  },

  input: {
    width: {
      xs: '100%',
      sm: '46%',
      md: '25%'
    },
    position: 'relative'
  },

  modalRadio: {
    '& .MuiFormGroup-root': {
      display: 'flex',
      flexDirection: 'row'
    }
  },

  actionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: {
      xs: 'flex-start',
      sm: 'center'
    },
    gap: '5px',
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
    padding: {
      xs: '15px',
      sm: '20px 15px'
    },
    marginBottom: '1em',
    borderRadius: '5px'
  },

  actionButtonWrapper: {
    display: 'flex',
    gap: 2
  },

  actionButton: {
    fontWeight: 500,
    cursor: 'pointer',
    borderRadius: '10px',
    padding: '10px 30px',
    backgroundColor: '#FFEFD3',
    border: '1px solid #FFAA1B'
  },

  contactInfo: {
    marginBottom: '2rem'
  },

  colorRed: {
    color: 'red'
  },

  eyePosition: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 10
  },

  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  updatePassword: {
    color: 'primary.main',
    cursor: 'pointer',
    textDecoration: 'underline'
  }
};

export default styles;
