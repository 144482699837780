const styles = {
  wrapper: {
    '& .MuiInputBase-input.Mui-disabled': {
      cursor: 'not-allowed'
    },

    '& .MuiInputBase-input': {
      padding: '4px 6px 5px'
    }
  },

  input: {
    position: 'relative'
    // height: {
    //   xs: '34px',
    //   sm: '38px'
    // }
  },

  label: {
    '& .MuiFormLabel-asterisk': {
      color: 'red'
    }
  },

  searchIconWrapper: {
    padding: '0 8px !important',
    marginRight: '-11px',
    borderRadius: '0 !important',
    height: {
      xs: '34px',
      sm: '38px'
    },
    borderLeft: '1px solid',
    borderColor: 'custom.gray'
  },

  closeIconWrapper: {
    position: 'absolute',
    bottom: -1.5
  },

  icon: {
    color: '#232F3E'
  }
};

export default styles;
