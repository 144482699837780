/**
 * Stepper List to add or delete items in the stepper
 */

export const stepperList = [
  {
    heading: 'Overview',
    subHeadings: [{ label: '', route: '' }],
    role: 'ROLE_gate_yard_dock',
    icon: '/box.svg'
  },
  {
    heading: 'Gate In',
    subHeadings: [
      {
        label: 'Search ACN',
        route: '/search-acn'
      },
      {
        label: 'Create manual ACN',
        route: '/create-manual-acn'
      }
    ],
    role: 'ROLE_gate_yard_dock',
    icon: '/box.svg'
  },
  {
    heading: 'Gate Out',
    subHeadings: [
      {
        label: 'Allow Gate Out',
        route: '/allow-gate-out'
      }
    ],
    role: 'ROLE_gate_yard_dock',
    icon: '/box.svg'
  },
  {
    heading: 'Dock',
    subHeadings: [
      {
        label: 'Receive on dock',
        route: '/receive-on-dock'
      },
      {
        label: 'Start Loading / Unloading',
        route: '/start-loading-unloading'
      },
      {
        label: 'Complete Loading/ Unloading',
        route: '/complete-loading-unloading'
      },
      {
        label: 'Release from dock',
        route: '/release-from-dock'
      }
    ],
    role: 'ROLE_gate_yard_dock',
    icon: '/box.svg'
  },
  {
    heading: 'Inbound Orders',
    subHeadings: [
      {
        label: 'Create PO',
        route: '/create-purchase-order'
      },
      {
        label: 'View Orders',
        route: '/view-orders'
      },
      {
        label: 'Find Orders',
        route: '/find-orders'
      }
    ],
    role: 'ROLE_inbound',
    icon: '/box.svg'
  },
  {
    heading: 'Inbound Consignment',
    subHeadings: [
      {
        label: 'View Consignment',
        route: '/view-consignment'
      },
      {
        label: 'Find Consignment',
        route: '/find-consignment'
      }
    ],
    role: 'ROLE_inbound',
    icon: '/box.svg'
  },
  {
    heading: 'Inbound Return Orders',
    subHeadings: [
      {
        label: 'Find Return Order',
        route: '/find-return-order'
      },
      {
        label: 'View Return Order',
        route: '/view-return-order'
      },
      {
        label: 'Create Return Order',
        route: '/create-return-order'
      }
    ],
    role: 'ROLE_inbound',
    icon: '/box.svg'
  },
  {
    heading: 'IRN',
    subHeadings: [
      {
        label: 'Create IRN',
        route: '/create-irn'
      },
      {
        label: 'Receive IRN',
        route: '/receive-irn'
      },
      {
        label: 'View IRN Stock',
        route: '/view-irn'
      }
    ],
    role: 'ROLE_inbound',
    icon: '/box.svg'
  },
  {
    heading: 'Putaways',
    subHeadings: [
      {
        label: 'Create Putlist',
        route: '/create-putlist'
      },
      {
        label: 'Complete Putlist',
        route: '/complete-putlist'
      },
      {
        label: 'Find Putlist',
        route: '/find-putlist'
      },
      {
        label: 'View Putlist',
        route: '/view-putlist'
      }
    ],
    role: 'ROLE_inbound',
    icon: '/box.svg'
  },
  {
    heading: 'Browse Product',
    subHeadings: [
      {
        label: 'Create Product',
        route: '/create-product'
      },
      {
        label: 'Find Product',
        route: '/find-product'
      },
      {
        label: 'View Product',
        route: '/view-product'
      }
    ],
    role: 'ROLE_inventory',
    icon: '/box.svg'
  },
  {
    heading: 'Browse Inventory',
    subHeadings: [
      {
        label: 'Search Inventory',
        route: '/search-inventory'
      }
    ],
    role: 'ROLE_inventory',
    icon: '/box.svg'
  },
  {
    heading: 'Stock Transfer',
    subHeadings: [
      {
        label: 'Create Transfer',
        route: '/create-transfer'
      },
      {
        label: 'Pick Transfer',
        route: '/pick-transfer'
      },
      {
        label: 'Put away Transfer',
        route: '/put-away-transfer'
      },
      {
        label: 'Search Transfer',
        route: '/search-transfer'
      },
      {
        label: 'View Transfer',
        route: '/view-transfer'
      }
    ],
    role: 'ROLE_inventory',
    icon: '/box.svg'
  },
  {
    heading: 'Outbound',
    subHeadings: [
      {
        label: 'Create SO',
        route: '/create-so'
      },
      {
        label: 'All Orders',
        route: '/all-orders'
      },
      {
        label: 'Pick',
        route: '/pick'
      },
      {
        label: 'Pack',
        route: '/pack'
      },
      {
        label: 'Manifest',
        route: '/manifest'
      }
    ],
    role: 'ROLE_outbound',
    icon: '/box.svg'
  },
  {
    heading: 'Return',
    subHeadings: [
      {
        label: 'Create RO',
        route: '/create-ro'
      },
      {
        label: 'Return Manifest',
        route: '/return-manifest'
      },
      {
        label: 'Staging Inventory',
        route: '/staging-inventory'
      },
      {
        label: 'Put back',
        route: '/put-back'
      }
    ],
    role: 'ROLE_returns',
    icon: '/box.svg'
  },
  {
    heading: 'Bundling',
    subHeadings: [
      {
        label: 'Manage Bundle',
        route: '/bundle-details'
      },
      {
        label: 'All Orders',
        route: '/all-bundling-orders'
      },
      {
        label: 'Issue',
        route: '/issue'
      },
      {
        label: 'Receive',
        route: '/receive'
      }
    ],
    role: 'ROLE_bundling',
    icon: '/box.svg'
  },
  {
    heading: 'Packaging',
    subHeadings: [
      {
        label: 'Generate Lot',
        route: '/generate-lot'
      },
      {
        label: 'Issue to SBS',
        route: '/issue-to-sbs'
      }
    ],
    role: 'ROLE_packaging',
    icon: '/box.svg'
  },
  {
    heading: 'User Privilege',
    subHeadings: [
      {
        label: 'Manage User Group',
        route: '/manage-user-group'
      },
      {
        label: 'Manage Users',
        route: '/manage-users'
      }
    ],
    role: 'Vendor',
    icon: '/box.svg'
  },
  {
    heading: 'Master Management',
    subHeadings: [
      {
        label: 'Facility',
        route: '/facility'
      },
      {
        label: 'Warehouse',
        route: '/warehouse'
      },
      {
        label: 'Suppliers',
        route: '/suppliers'
      }
    ],
    role: 'master',
    icon: '/box.svg'
  }
];

export const dummyRoles = ['ROLE_inbound', 'ROLE_returns'];
