const styles = {
  icon: {
    color: 'black',
    fontSize: {
      xs: '22.5px',
      sm: '26.8px'
    },
    zIndex: 5
  },

  menuIcon: {
    color: 'black'
  },

  navBarIcon: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: '20px'
  },

  mobileDropDown: {
    display: {
      xs: 'block',
      md: 'none'
    }
  },

  mobileDropdown: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: '15px 10%',
    width: '200px'
  },

  dropDownWrapper: {
    width: '100%',
    flexWrap: 'wrap',
    gap: '20px',
    display: {
      xs: 'none',
      md: 'flex'
    }
  },

  dropDown: {
    width: {
      sm: '35%',
      md: '15%'
    }
  },

  drawerIcon: {
    color: 'white'
  },

  navBarWrapper: {
    width: '100%',
    padding: '8px',
    display: 'flex',
    position: 'sticky',
    top: 0,
    zIndex: 100,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'rgb(255, 255, 255)',
    borderBottom: '1px solid #EAEBEE',
    height: '62px'
  },

  navBarLogo: {
    width: {
      xs: '85px',
      md: '110px'
    },
    cursor: 'pointer'
  },

  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: {
      xs: '8px',
      sm: '12px'
    }
  },

  logOutWrapper: {
    padding: '10px',
    width: '180px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'primary.main',
      color: 'white'
    }
  }
};

export default styles;
