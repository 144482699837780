// Messages
export const SUCCESS_MSG = 'SUCCESS';
export const ERROR_MSG = 'ERROR';
export const ERROR_MESSAGE = 'Oops, something went wrong';

// Temporary
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';
export const ERRORMESSAGE = 'Oops, something went wrong';
// keycloak constants
export const GRANT_TYPE_PASSWORD = 'password';
export const GRANT_TYPE_REFRESH_TOKEN = 'refresh_token';
export const CLIENT_ID = 'wms-client';
