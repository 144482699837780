const styles = {
  formWrapper: {
    position: 'relative',
    margin: '0 auto',
    padding: {
      xs: '0 5.1%',
      sm: '0 6.9%',
      md: '0 35px'
    },
    height: 'min-content',
    // width: {
    //   xs: '400px',
    //   sm: '650px',
    //   md: '950px'
    // },
    backgroundColor: 'white',
    borderRadius: '16px',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
  },

  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 0px'
  },

  heading: {
    fontSize: '20px',
    fontWeight: 600
  },

  divider: {
    position: 'absolute',
    left: 0,
    right: 0,
    border: '1px solid #EAEBEE'
  },

  child: {
    padding: '30px 0px'
  }
};

export default styles;
