const styles = {
  inputWrapper: {
    '& .MuiTextField-root': {},

    '& .MuiOutlinedInput-input': {
      padding: '0 !important',
      height: {
        xs: '34px',
        sm: '38px'
      }
    },

    '& label.Mui-focused': {
      color: 'rgb(34, 34, 34,0.5)'
    },

    '& label.Mui-error': {
      color: '#ac0d1f'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none'
      },
      '&:hover fieldset': {
        borderColor: 'rgb(34, 34, 34,0.5)'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0858cf'
      },
      '&.Mui-error fieldset': {
        borderColor: '#EC0909'
      },
      height: {
        xs: '34px',
        sm: '38px'
      }
    }
  },

  error: {
    margin: '0 !important'
  }
};

export default styles;
