import { Box } from '@mui/material';
import React from 'react';
import { Input } from '../../../common';
import styles from '../styles';

const AccountInformation = ({ control }) => {
  return (
    <Box sx={styles.formWrapper}>
      <Input
        name="companyName"
        label="Company Name"
        isRequired
        customStyles={styles.input}
        control={control}
        rules={{ required: 'This is a required field' }}
      />
      <Input
        name="email"
        label="E-mail"
        isRequired
        customStyles={styles.input}
        control={control}
        rules={{ required: 'This is a required field' }}
      />
    </Box>
  );
};

export default AccountInformation;
