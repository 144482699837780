import { Box } from '@mui/material';
import React from 'react';
import DynamicButton from '../FormComponents/Button';
import styles from './styles';

const FormWrapper = ({ heading, buttonLabel = 'Save', buttonClick, children }) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.headerWrapper}>
        <Box sx={styles.heading}>{heading}</Box>
        <DynamicButton label={buttonLabel} customStyles={styles.button} onClick={buttonClick} />
      </Box>
      <Box sx={styles.container}>{children}</Box>
    </Box>
  );
};

export default FormWrapper;
