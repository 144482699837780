import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import MatModal from '@mui/material/Modal';
import Button from '../Button';
import styles from './styles';

const Modal = ({
  open,
  heading,
  children,
  handleClose,
  onSuccess,
  buttonLabel,
  hideAcnBtn = false,
  disabled,
  customStyles
}) => {
  return (
    <MatModal open={open} onClose={handleClose} sx={styles.mainWrapper} closeAfterTransition>
      <Box sx={{ ...styles.wrapper, ...customStyles }}>
        <Box sx={styles.heading}>{heading}</Box>
        <IconButton sx={styles.iconWrapper} onClick={handleClose}>
          <HighlightOffIcon sx={styles.closeIcon} />
        </IconButton>
        <Box>{children}</Box>
        {!hideAcnBtn && (
          <Box sx={styles.buttonWrapper}>
            <Button label={buttonLabel} onClick={onSuccess} disabled={disabled} />
          </Box>
        )}
      </Box>
    </MatModal>
  );
};

export default Modal;
