import Box from '@mui/material/Box';
import styles from './styles';

const Form = ({ heading, description, children }) => {
  return (
    <Box sx={styles.formWrapper} className="form-wrapper">
      <Box sx={styles.headerWrapper}>
        <Box sx={styles.heading}>{heading}</Box>
        <Box>{description}</Box>
      </Box>
      <Box sx={styles.divider}></Box>
      <Box sx={styles.child}>{children}</Box>
    </Box>
  );
};

export default Form;
