import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userLogin } from '../../../../redux/APICaller/authAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import { Checkbox, DynamicButton, Input, Password } from '../../../common';
import { DefaultSkeletton } from '../../../common/WebSkeleton';
import { SUCCESS, INTERNAL_SERVER_ERROR } from '../../../../constants/apiStatusCodes';
import { ERROR_MESSAGE, SUCCESS_MSG, ERROR_MSG } from '../../../../constants/constants';
import styles from '../styles';

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    control,
    formState: { errors },
    trigger,
    getValues
  } = useForm({
    defaultValues: {
      username: '',
      password: ''
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (data) => userLogin(data),
    onSuccess: (res) => {
      if (res?.status === SUCCESS) {
        if (res?.data?.message)
          dispatch(showToastMessage({ messageType: SUCCESS_MSG, message: res?.data?.message }));
        localStorage.setItem('wms_access_token', res.data.access_token);
        localStorage.setItem('wms_refresh_token', res.data.refresh_token);
        localStorage.setItem(
          'wms_access_roles',
          `${
            res.data.type_of_user === 'Vendor'
              ? `${res.data.roles},${res.data.type_of_user}`
              : res.data.roles
          }`
        );
        localStorage.setItem('wms_groups', res.data.groups);
        localStorage.setItem('type_of_user', res.data.type_of_user);
        navigate(`/`);
      } else if (res?.status !== INTERNAL_SERVER_ERROR) {
        dispatch(
          showToastMessage({ messageType: ERROR_MSG, message: res?.data?.message || ERROR_MESSAGE })
        );
      }
    },
    onError: () => {
      dispatch(showToastMessage({ messageType: ERROR_MSG, message: ERROR_MESSAGE }));
    }
  });

  const handleClick = (res) => {
    if (res) {
      const { username, password } = getValues();
      mutate({ username, password });
    }
  };

  return (
    <Box sx={styles.wrapper}>
      <Box>
        <Box sx={styles.heading}>Hi, Welcome Back</Box>
        <Box sx={styles.description}>Loren epsom simply dummy text for text use.</Box>
      </Box>
      <Box sx={styles.inputWrapper}>
        <Input
          name="username"
          label="Email"
          placeholder="Email Address"
          loading={isPending}
          customStyles={styles.input}
          startIcon={<MailOutlinedIcon />}
          control={control}
          errors={errors}
          rules={{
            required: 'This is a required field',
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: 'Please provide a valid email'
            }
          }}
        />
        <Password
          name="password"
          label="Password"
          customStyles={styles.input}
          placeholder="Password"
          loading={isPending}
          startIcon={<LockOutlinedIcon />}
          control={control}
          errors={errors}
          rules={{ required: 'This is a required field' }}
        />
      </Box>
      <Box sx={styles.authWrapper}>
        <DefaultSkeletton loading={isPending}>
          <Checkbox name="rememberMe" label="Remember Me" control={control} />
        </DefaultSkeletton>
        <DefaultSkeletton loading={isPending}>
          <Box sx={styles.linkers} onClick={() => navigate('/forgot-password')}>
            Forgot Password?
          </Box>
        </DefaultSkeletton>
      </Box>
      <DynamicButton
        label="Log in"
        customStyles={styles.button}
        disabled={isPending}
        onClick={() => trigger().then((res) => handleClick(res))}
      />
      <Box sx={styles.footer}>
        <DefaultSkeletton loading={isPending} customStyles={styles.footerLoading}>
          <Box>
            Don’t have an account?{' '}
            <Box component="span" sx={styles.linkers} onClick={() => navigate('/sign-up')}>
              Sign up
            </Box>
          </Box>
        </DefaultSkeletton>
      </Box>
    </Box>
  );
};

export default SignIn;
