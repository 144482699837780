import { Box } from '@mui/material';
import React from 'react';
import { Dropdown, Input, Radio } from '../../../common';
import styles from '../styles';

const CreateWarehouse = ({ control }) => {
  return (
    <Box sx={styles.formWrapper}>
      <Input
        name="warehouseName"
        label="Warehouse Name"
        control={control}
        customStyles={styles.input}
        isRequired
        rules={{ required: 'This is a required field' }}
      />
      <Input
        name="warehouseCode"
        label="Warehouse Code"
        control={control}
        customStyles={styles.input}
        isRequired
        rules={{ required: 'This is a required field' }}
      />
      <Input
        name="facilityId"
        label="Facility ID"
        control={control}
        customStyles={styles.input}
        isRequired
        rules={{ required: 'This is a required field' }}
      />
      <Dropdown
        name="supplierId"
        label="Supplier ID"
        control={control}
        customStyles={styles.input}
        isRequired
        rules={{ required: 'This is a required field' }}
        options={[{ label: 'Value 1', value: 'value1' }]}
      />
      <Input
        name="econSiteCode"
        label="Econ Site Code"
        control={control}
        customStyles={styles.input}
        isRequired
        rules={{ required: 'This is a required field' }}
      />
      <Dropdown
        name="manageCategory"
        label="Manage Category"
        control={control}
        customStyles={styles.input}
        isRequired
        rules={{ required: 'This is a required field' }}
        options={[{ label: 'Value 1', value: 'value1' }]}
      />
      <Input
        name="supplierName"
        label="Supplier Name"
        control={control}
        customStyles={styles.input}
        isRequired
        rules={{ required: 'This is a required field' }}
      />
      <Radio
        name="status"
        label="Status"
        isRequired
        control={control}
        customStyles={styles.radioButton}
        options={[
          {
            label: 'Enable',
            value: 'enable'
          },
          {
            label: 'Disable',
            value: 'disable'
          }
        ]}
      />
    </Box>
  );
};

export default CreateWarehouse;
