const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: '8px 15px',
    boxSizing: 'border-box',
    backgroundColor: 'white',
    maxWidth: 400,
    minHeight: 270
  },

  heading: {
    fontSize: '16px',
    fontWeight: 400
  },

  mainList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px'
  },

  listWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '16px',
    fontWeight: 400
  }
};

export default styles;
