const styles = {
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',

    '& .MuiInputBase-input.Mui-disabled': {
      cursor: 'not-allowed'
    }
  },

  button: {
    height: '38px',
    width: {
      xs: '150px',
      md: '160px'
    }
  },

  popOverWrapper: {
    position: 'absolute',
    zIndex: 100,
    top: -32,
    right: 0
  },

  popover: {
    '& .MuiPopover-paper': {
      width: {
        xs: '300px',
        sm: '400px'
      },
      maxHeight: '400px',
      objectFit: 'cover'
    }
  },

  icon: {
    fontSize: '20px'
  },

  errorMessage: {
    fontSize: {
      xs: '12px',
      sm: '14px'
    },
    marginTop: '-1em',
    color: '#D32F2F'
  },

  uploading: {
    fontSize: {
      xs: '12px',
      sm: '14px'
    },
    marginTop: '-1em',
    color: 'custom.gray'
  },

  fileUploadLabel: {
    color: 'custom.gray',
    fontSize: {
      xs: '12px',
      sm: '14px'
    },
    marginBottom: '2px'
  },

  fileName: {
    textDecoration: 'none',
    color: 'primary.main',
    textTransform: 'capitalize',
    '&:hover': {
      textDecoration: 'underline'
    }
  },

  input: {
    height: {
      xs: '34px',
      sm: '38px'
    }
  }
};

export default styles;
