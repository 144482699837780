import { Box } from '@mui/material';
import React from 'react';
import { statesOfIndia } from '../../../../constants/states';
import { Dropdown, Input, Radio } from '../../../common';
import styles from '../styles';

const FacilityDetails = ({ control }) => {
  return (
    <Box sx={styles.formWrapper}>
      <Input
        name="facilityName"
        label="Facility Name"
        control={control}
        customStyles={styles.input}
        isRequired
        rules={{ required: 'This is a required field' }}
      />
      <Dropdown
        name="country"
        label="Country"
        disabled
        control={control}
        customStyles={styles.input}
        isRequired
        rules={{ required: 'This is a required field' }}
        options={[{ label: 'India', value: 'india' }]}
      />
      <Dropdown
        name="state"
        label="State"
        control={control}
        customStyles={styles.input}
        isRequired
        rules={{ required: 'This is a required field' }}
        options={statesOfIndia}
      />
      <Input
        name="city"
        label="City"
        control={control}
        customStyles={styles.input}
        isRequired
        rules={{ required: 'This is a required field' }}
      />
      <Input
        name="street"
        label="Street"
        control={control}
        customStyles={styles.input}
        isRequired
        rules={{ required: 'This is a required field' }}
      />
      <Input
        name="facilityCode"
        label="Facility/Location Code"
        control={control}
        customStyles={styles.input}
        isRequired
        rules={{ required: 'This is a required field' }}
      />
      <Input
        name="zipCode"
        label="Zip Code"
        control={control}
        customStyles={styles.input}
        isRequired
        rules={{ required: 'This is a required field' }}
      />
      <Input
        name="postalAddress"
        label="Postal Address"
        control={control}
        customStyles={styles.input}
        isRequired
        rules={{ required: 'This is a required field' }}
      />
      <Input
        name="tehsil"
        label="Taluk/Tehsil"
        control={control}
        customStyles={styles.input}
        isRequired
        rules={{ required: 'This is a required field' }}
      />
      <Input
        name="fullAddress"
        label="Full Address"
        control={control}
        customStyles={styles.input}
        isRequired
        rules={{ required: 'This is a required field' }}
      />
      <Radio
        name="status"
        label="Status"
        isRequired
        control={control}
        customStyles={styles.radioButton}
        options={[
          {
            label: 'Enable',
            value: 'enable'
          },
          {
            label: 'Disable',
            value: 'disable'
          }
        ]}
      />
    </Box>
  );
};

export default FacilityDetails;
