import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  companyName: '',
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  fullAddress: '',
  country: 'india',
  state: '',
  zipCode: '',
  city: '',
  fullBillingAddress: '',
  isCompleted: false
};

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    userProfile: (state, action) => {
      state = Object.assign(state, action.payload);
    }
  }
});

export const { userProfile } = userProfileSlice.actions;
export default userProfileSlice.reducer;
