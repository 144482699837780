import { Box } from '@mui/material';
import React from 'react';
import { statesOfIndia } from '../../../../constants/states';
import { Dropdown, Input } from '../../../common';
import styles from '../styles';

const BillingAddress = ({ control }) => {
  return (
    <Box sx={styles.formWrapper}>
      <Dropdown
        name="country"
        label="Country"
        control={control}
        disabled
        options={[{ label: 'India', value: 'india' }]}
        customStyles={styles.input}
      />
      <Dropdown
        name="state"
        label="State"
        control={control}
        options={statesOfIndia}
        customStyles={styles.input}
      />
      <Input name="zipCode" label="Zip Code" control={control} customStyles={styles.input} />
      <Input name="city" label="City" control={control} customStyles={styles.input} />
      <Input name="fullBillingAddress" label="Full Address" control={control} />
    </Box>
  );
};

export default BillingAddress;
